<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">提柜供应商维护</span>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<el-button @click="openAdd($event,null)" type="primary" size="small"
								icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>

							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<ul class="filterConList">
							<!-- <li>
                    <span>{{$t('i18nn_cabc5dc617eef30e')}}</span>

                    <el-date-picker
                      v-model="filterData.statisticsCurrentDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      size="small"
                      :placeholder="$t('68efabd029c860a9')"
                      @change="initData()"
                    ></el-date-picker>
                  </li> -->
							<!-- <li>
                  	<span>{{ $t('hytxs0000039') }}</span>
                  	<el-input
                  		type="text"
                  		v-model="filterData.keywords"
                  		size="small"
                  		clearable
                  		@keyup.enter.native="initData()"
                  		maxlength="50"
                  		:placeholder="$t('hytxs0000038')"
                  		style="width: 180px;"
                  	/>
                  </li> -->

							<!-- <li>
                    <span>{{$t('c944a6686d996ab3')}}</span>
                    <el-select
                    	size="small"
                    	filterable
                    	clearable
                    	v-model="filterData.whNo"
                    	:placeholder="$t('2ad108ab2c560530')"
                    	style="width: 200px;" 
                      @change="initData()"
                    >
                    	<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>
                    </el-select>
                    
                  </li> -->
							<!-- <li>
									  <span>{{$t('hytxs0000036')}}</span>
									  <el-select
									  	size="small"
									  	filterable
									  	clearable
									  	v-model="filterData.wh_place_type"
									  	:placeholder="$t('2ad108ab2c560530')"
									  	style="width: 200px;" 
									    @change="initData()"
									  >
									  	<el-option v-for="item in selectOption.wh_place_type"  :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>							  </el-select>
									</li> -->
							<!-- <li>
										<span>{{ $t('hytxs0000034') }}</span>
										<el-input
											type="text"
											v-model="filterData.pArea"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('hytxs0000001')"
											style="width: 180px;"
										/>
									</li>
									<li>
										<span>{{ $t('hytxs0000035') }}</span>
										<el-input
											type="text"
											v-model="filterData.place"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('hytxs0000001')"
											style="width: 180px;"
										/>
									</li> -->
							<!-- </ul> -->
							<!-- <ul class="filterConList"> -->
							<li>
								<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('custom_enterfuzzysearch')"
									style="width: 180px;" />
							</li>

							<li>
								<el-button icon="el-icon-search" size="small" type="primary"
									@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
							</li>
						</ul>
					</div>
				</div>

				<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true"
					:height="$store.state.tableMaxHeight3" @row-click="handleCurrentChange"
					@selection-change="handleSelectionChange" style="width: 100%" size="small" v-loading="loading">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>
					<!--  -->
					<el-table-column prop="carrierCode" :label="'供应商编码'" width="110">
					</el-table-column>
					<el-table-column prop="carrieCompany" :label="'供应商名称'" width="150">
					</el-table-column>
					<el-table-column prop="mailAddr" :label="'接收DO邮箱'" width="150">
					</el-table-column>
					<el-table-column prop="isApi" :label="'是否API'" width="130">
					</el-table-column>
					<el-table-column prop="apiUrl" :label="'apiUrl'" width="180">
						<template slot-scope="scope">
							<div v-if="scope.row.apiInfoVo">
								{{scope.row.apiInfoVo.apiUrl}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="clientKey" :label="'clientKey'" width="180">
						<template slot-scope="scope">
							<div v-if="scope.row.apiInfoVo">
								{{scope.row.apiInfoVo.clientKey}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="clientSecret" :label="'clientSecret'" width="180">
						<template slot-scope="scope">
							<div v-if="scope.row.apiInfoVo">
								{{scope.row.apiInfoVo.clientSecret}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" :label="'其他信息'" min-width="310">
						<template slot-scope="scope">
							<div>
								<div v-for="(item,index) in scope.row.caerrierDsrcVos" :key="index">
									<span>{{item.key}}</span>:
									<span>{{item.value}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="" :label="$t('i18nn_1cacee9f09138a3d')" min-width="310">
						<template slot-scope="scope">
							<div>
								<div v-for="(item,index) in scope.row.channels" :key="index">
									<span>{{item.key}}:</span>
									<span>{{item.name}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" :label="$t('i18nn_ade48dee835e25c0')" min-width="310">
						<template slot-scope="scope">
							<div>
								<div v-for="(item,index) in scope.row.signatures" :key="index">
									<span>{{item.key}}:</span>
									<span>{{item.name}}</span>
								</div>
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="remark" :label="$t('15b3627faddccb1d')" width="150" show-overflow-tooltip>
					</el-table-column>
					<!-- <el-table-column prop="orderSort" :label="$t('i18nn_dbcffdf31e86d2d8')" width="150" show-overflow-tooltip>
					</el-table-column> -->
					<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')" width="150"></el-table-column>
					<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')" width="150"></el-table-column>
					
					<el-table-column prop="id" label="ID"></el-table-column>
					
					<el-table-column :label="$t('93f5ca01b006206c')" width="280px" fixed="right">
						<template slot-scope="scope">
							<div>
								<!-- <div v-if="isSel">
									<el-button @click="selRow($event, scope.row, scope.$index)" v-if="!scope.row.dataFlag" type="success"
										size="mini" icon="el-icon-magic-stick">
										{{ $t('7fb6ada66a1fccca') }}
									</el-button>
								</div> -->
								<div>
									<el-button @click="openEdit($event, scope.row, scope.$index)"
										type="warning" size="mini" icon="el-icon-edit">
										{{ $t('6267f3aedf895209') }}
									</el-button>

									<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">
										{{ $t('e33c9b93c36fd250') }}
									</el-button>
								</div>

								<!-- <div style="margin-top: 10px;">
									<el-button @click="queryBalenceAction($event, scope.row)" type="success" size="mini"
										icon="el-icon-search">{{$t('i18nn_8d337267addff5d5')}}</el-button>
								</div> -->
							</div>

						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="tableConPagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!-- 新增 -->
		<CabinetPickupCarrierAdd :openTime="EditOpentTime" :row="EditRow" @success="EditSuccess"></CabinetPickupCarrierAdd>

		<!-- excel 导出排序 -->
		<!-- <whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination" :excelName="excelName" :excelOption="excelOption"
		:expHttpUrl="$urlConfig.WhPlacePageList" :expHttpFilter="pageFilterData()"></whExcelCustom> -->

	</div>
</template>
<script>
	// import JQ from 'jquery'
	import {
		deepClone
	} from '@/utils/utils.js';
	// import excelUtils from '@/utils/excelUtils.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';

	// import { htmlToPdf } from '@/utils/pdfUtils.js';

	// import barcode from '@/components/Common/barcode.vue';
	import CabinetPickupCarrierAdd from '@/components/WarehouseCenter2/CabinetPickup/CabinetPickupCarrierAdd.vue';
	// import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';


	export default {
		components: {
			// barcode,
			CabinetPickupCarrierAdd
			// whExcelCustom
		},
		// props: {
		// 	// mobile:"",
		// 	whNo: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	},
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	}
		// },
		data() {
			return {
				//excel导出
				// excelOpenTime:'',
				// excelHead:[],
				// expExcelData:[],
				// excelName:"",
				// excelOption:{},
				EditOpentTime: "",
				EditRow: {},
				//打开入库选择框
				// dialogSelVisible: false,
				// dialogVisible2:false,
				// loading_pdf: false,
				// PdfData:[],
				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//缓存，原数据
				// tableDataCatch: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				selectOption: {
					// wh_no: [],
					// biz_wh_lgskey_code: [],
					// isUse: [{
					// 	code: "0",
					// 	codeText: this.$t('i18nn_ab5902ee5c645e01'),
					// }, {
					// 	code: "1",
					// 	codeText: this.$t('i18nn_e0199d6ecc3ee81a'),
					// }, ]
				},

				filterData: {
					orderBy: 'create_time', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// whNo: '',
					keywords: '',
					// pArea: '', //this.$t('hytxs0000034'),
					// place: '', //this.$t('hytxs0000035'),
					// keyword: "",
					// wh_place_type: ""
					
				}
			};
		},
		// watch: {
		// 	whNo(to, from) {
		// 		this.filterData.whNo = this.whNo;
		// 		this.initData();
		// 	}
		// },
		activated() {
			this.initData();
		},
		//创建时
		created() {


		},
		//编译挂载前
		mounted() {
			//数据字典
			// getDicData(['biz_wh_lgskey_code'],
			// 	(data) => {
			// 		this.selectOption.biz_wh_lgskey_code = data['biz_wh_lgskey_code'];
			// 	});
			// this.getWhNoData();
		},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.initTable();
				this.getPageData();

			},
			initTable() {
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
				this.$forceUpdate();
			},

			//选择仓库
			changeDataWhNo(data) {
				console.log('changeDataWhNo', data);
				this.form.whNo = data.code;
			},

			//新增
			openAdd(event, row) {
				event.stopPropagation();
				this.EditRow = row;
				this.EditOpentTime = new Date().getTime();
			},

			EditSuccess() {
				this.initData();
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				console.log('openEdit', row);
				this.openAdd(event, row);
			},

			//余额查询
			// queryBalenceAction(event, row) {
			// 	event.stopPropagation();
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhExpreeSheetQueryBalence, {
			// 			"lgsKeyCode": row.code
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_no = data.rows;
			// 				let balance = data.data.balance;
			// 				if (balance) {
			// 					this.$alert('余额：' + data.data.balance, this.$t('cc62f4bf31d661e3'), {
			// 						type: 'success',
			// 					});
			// 				} else {
			// 					this.$alert(data.data.msg, this.$t('cc62f4bf31d661e3'), {
			// 						type: 'warning',
			// 					});
			// 				}

			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('i18nn_74b62cbb161a84a4') + ',' + this.$t('i18nn_4eb3309d6692dbd4');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_323ee425eca7208c'));
			// 			this.loading_load = false;
			// 			this.$message.error(this.$t('i18nn_74b62cbb161a84a4') + ',' + this.$t('i18nn_4eb3309d6692dbd4') + '！');
			// 		});
			// },
			//删除
			delAction(event, row) {
				this.$confirm(this.$t('i18nn_d96afd0c0de3686c') + '?', this.$t('daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {

					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);

				this.postData(this.$urlConfig.WhCabinetPickupCarrierDel, {
					ids: [row.id]
				}, () => {
					this.initData();
					this.$message.success(this.$t('9f30371831a98237'));
				}, '');
			},

			//分页的筛选项数据
			pageFilterData() {
				return {
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// place: this.filterData.place ? this.filterData.place : null,
					// pArea: this.filterData.pArea ? this.filterData.pArea : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// placeType: this.filterData.wh_place_type ? this.filterData.wh_place_type : null,
				};
			},

			//请求分页数据
			getPageData() {
				this.loading_load = true;
				// console.log('filterData1',this.pageFilterData());
				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.$http
					.put(this.$urlConfig.WhCabinetPickupCarrierList, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);

						this.loading_load = false;
						if (200 == data.code) {
							let rowData = data.rows;

							//表格显示数据
							this.tableData = rowData;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//提交信息
			postData(url, formData, callBack, type) {
				this.loading = true;
				let httpPro = null;
				if ('delete' == type) {
					httpPro = this.$http.delete(url, {})
				} else {
					httpPro = this.$http.put(url, formData)
				}
				httpPro.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {

							callBack();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>