<template>
	<!-- <el-dialog :title="$t('c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"> -->
	<el-drawer :wrapperClosable="false" :title="!!modifyForm.id?$t('6267f3aedf895209'):$t('i18nn_24c8f46012a25c89')"
		append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="800px">

		<div>
			<div style="">
				<el-form ref="modifyForm" size="small" :rules="modifyFormRules" :model="modifyForm" label-width="100px"
					v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
					<!-- <el-alert
					    :title="$t('daaaeb1b7b22b126')"
					    type="warning"
					    description="管理员修改,只能为数字,字母, - , _ "
					    show-icon>
					  </el-alert> -->
					<!-- <el-form-item :label="$t('i18nn_e4842b8507dd415c')" prop="">
						<el-input type="text" clearable v-model="modifyForm.nickName"></el-input>
					</el-form-item> -->
					<!-- <el-form-item :label="$t('i18nn_a9dc5000637ff647')" prop="">
						<el-select size="" filterable clearable v-model="modifyForm.isUse" :placeholder="$t('2ad108ab2c560530')"
							style="">
							<el-option v-for="item in selectOption.isUse" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option> </el-select>
					</el-form-item> -->

					<el-form-item :label="'供应商编码'" prop="">
						<el-input size="" v-model="modifyForm.carrierCode" :placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>
					<!-- <el-form-item :label="'lgskey'" prop="">
						<el-select size="" filterable clearable v-model="modifyForm.code" :placeholder="$t('5a9aefbc03c778f7')"
							style="">
							<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option></el-select>
					</el-form-item> -->
					<el-form-item :label="'供应商名称'" prop="">
						<el-input type="text" size="" v-model="modifyForm.carrieCompany" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>
					
					<el-form-item :label="'接收DO邮箱'" prop="">
						<el-input type="text" size="" v-model="modifyForm.mailAddr" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>

					<el-form-item :label="'是否API'" prop="">
						<!-- <el-input type="text" size="" v-model="modifyForm.isApi" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input> -->

						<el-select size="" filterable clearable v-model="modifyForm.isApi" :placeholder="$t('2ad108ab2c560530')"
							style="">
							<el-option v-for="item in selectOption.isApi" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option> </el-select>

					</el-form-item>

					<el-form-item :label="'apiUrl'" prop="" v-if="modifyForm.apiInfoVo">
						<el-input type="text" size="" v-model="modifyForm.apiInfoVo.apiUrl" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>
					<el-form-item :label="'clientKey'" prop="" v-if="modifyForm.apiInfoVo">
						<el-input type="text" size="" v-model="modifyForm.apiInfoVo.clientKey" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>
					<el-form-item :label="'clientSecret'" prop="" v-if="modifyForm.apiInfoVo">
						<el-input type="text" size="" v-model="modifyForm.apiInfoVo.clientSecret" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>

					<el-form-item :label="'其他信息'" prop="">
						<div>
							<el-button @click="addContacAction()" type="primary" size=""
								icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button>
							<div v-for="(item,index) in modifyForm.caerrierDsrcVos" :key="index" style="margin-bottom: 5px;">
								<el-input size="" v-model="item.key" style="width: 100px;" />:
								<el-input size="" v-model="item.value" style="width: 100px;" />
								<el-button size="" @click="delContacAction(index)" type="warning"
									icon="el-icon-close">{{$t('i18nn_dc1706be2ffb6973')}}</el-button>

							</div>
						</div>
					</el-form-item>

					<!-- <el-form-item :label="$t('i18nn_1cacee9f09138a3d')" prop="">
						<div>
							<el-button @click="addChannelsAction()" type="primary" size=""
								icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button>
							<div v-for="(item,index) in modifyForm.channels" :key="index" style="margin-bottom: 5px;">
								<el-input size="" v-model="item.key" style="width: 80px;" />:
								<el-input size="" v-model="item.name" style="width: 140px;" />
								<el-button size="" @click="delChannelsAction(index)" type="warning"
									icon="el-icon-close">{{$t('i18nn_dc1706be2ffb6973')}}</el-button>

							</div>
						</div>
					</el-form-item> -->

					<!-- <el-form-item :label="$t('i18nn_ade48dee835e25c0')" prop="">
						<div>
							<el-button @click="addSignaturesAction()" type="primary" size=""
								icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button>
							<div v-for="(item,index) in modifyForm.signatures" :key="index" style="margin-bottom: 5px;">
								<el-input size="" v-model="item.key" style="width: 80px;" />:
								<el-input size="" v-model="item.name" style="width: 140px;" />
								<el-button size="" @click="delSignaturesAction(index)" type="warning"
									icon="el-icon-close">{{$t('i18nn_dc1706be2ffb6973')}}</el-button>

							</div>
						</div>
					</el-form-item> -->

					<el-form-item :label="$t('15b3627faddccb1d')" prop="">
						<el-input type="textarea" size="" :rows="3" v-model="modifyForm.remark" :maxlength="1000" show-word-limit
							:placeholder="$t('5a9aefbc03c778f7')"></el-input>
					</el-form-item>

					<!-- <el-form-item :label="$t('i18nn_dbcffdf31e86d2d8')" prop="">
						<el-input-number size="small" controls-position="right" v-model="modifyForm.orderSort"
							:placeholder="$t('5a9aefbc03c778f7')"></el-input-number>
					</el-form-item> -->


				</el-form>
			</div>
		</div>

		<div class="drawer-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{$t('FormMsg.Close')}}</el-button>
			<el-button type="primary" @click="SubmitForm('modifyForm')">{{$t('204ffab8a6e01870')}}</el-button>
		</div>
	</el-drawer>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	export default {

		props: {
			openTime: {},
			row: {}
		},
		components: {
			// whNoSelect
		},
		data() {
			return {
				dialogFile: false,
				loading: false,

				//修改密码
				modifyForm: {
					carrierCode: '', //供应商编码
					carrieCompany: '', //供应商名称
					mailAddr:'',//接收do邮箱
					isApi: '', //是否API
					apiInfoVo: {
						"apiUrl": "",
						"clientKey": "",
						"clientSecret": ""
					},
					caerrierDsrcVos: [
						// {
						// 	"key": "",
						// 	"value": ""
						// },
					],
				},
				// modifyForm: {
				// 	isUse: '',
				// 	logistics: '',
				// 	code: '',
				// 	clientKey: '',

				// 	clientSecret: '',
				// 	apiUrl: '',
				// 	descJson: [],
				// 	channels: [],
				// 	signatures: [],
				// 	remark: '',

				// 	orderSort: ''
				// },
				modifyFormRules: {
					// skuPrefix: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
				},
				selectOption: {
					isApi: [{
						code: "0",
						codeText: '否',
					}, {
						code: "1",
						codeText: '是',
					}, ],
					// biz_wh_lgskey_code: [],
					// wh_place_type: []
				}

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
			//数据字典
			// getDicData(['biz_wh_lgskey_code'],
			// 	(data) => {
			// 		this.selectOption.biz_wh_lgskey_code = data['biz_wh_lgskey_code'];
			// 	});
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {

				this.resetForm('modifyForm');
				//初始化上传控件
				if (this.row && this.row.id) {
					this.modifyForm = Object.assign({}, this.row);
					if (!this.modifyForm.apiInfoVo) {
						this.modifyForm.apiInfoVo = {
							"apiUrl": "",
							"clientKey": "",
							"clientSecret": ""
						}
					}

				} else {

				}

				// this.$nextTick(()=>{
				// 	try {
				// 		this.$refs.whNoSelect.init(this.modifyForm.whNo);
				// 	} catch(e) {
				// 		//TODO handle the exception
				// 		console.log(e);
				// 	}
				// });
			},

			//新增
			addContacAction() {
				// console.log('addContacAction',index);
				if (this.modifyForm.caerrierDsrcVos) {
					this.modifyForm.caerrierDsrcVos.push({
						value: '',
						key: ''
					});
				} else {
					this.modifyForm.caerrierDsrcVos = [];
					this.modifyForm.caerrierDsrcVos.push({
						value: '',
						key: ''
					});
				}
				this.$forceUpdate()
				// this.initTable();
			},
			//删除
			delContacAction(index) {
				// console.log('delContacAction',index);
				this.modifyForm.caerrierDsrcVos.splice(index, 1);
				// this.initTable();
				this.$forceUpdate()
			},

			//三方渠道
			// addChannelsAction() {
			// 	// console.log('addChannelsAction',index);
			// 	if (this.modifyForm.channels) {
			// 		this.modifyForm.channels.push({
			// 			key: '',
			// 			name: ''
			// 		});
			// 	} else {
			// 		this.modifyForm.channels = [];
			// 		this.modifyForm.channels.push({
			// 			key: '',
			// 			name: ''
			// 		});
			// 	}
			// 	this.$forceUpdate()
			// 	// this.initTable();
			// },
			// //删除
			// delChannelsAction(index) {
			// 	// console.log('delSignaturesAction',index);
			// 	this.modifyForm.channels.splice(index, 1);
			// 	// this.initTable();
			// 	this.$forceUpdate()
			// },

			//签名
			// addSignaturesAction() {
			// 	// console.log('addSignaturesAction',index);
			// 	if (this.modifyForm.signatures) {
			// 		this.modifyForm.signatures.push({
			// 			key: '',
			// 			name: ''
			// 		});
			// 	} else {
			// 		this.modifyForm.signatures = [];
			// 		this.modifyForm.signatures.push({
			// 			key: '',
			// 			name: ''
			// 		});
			// 	}
			// 	this.$forceUpdate()
			// 	// this.initTable();
			// },
			//删除
			// delSignaturesAction(index) {
			// 	// console.log('delSignaturesAction',index);
			// 	this.modifyForm.signatures.splice(index, 1);
			// 	// this.initTable();
			// 	this.$forceUpdate()
			// },

			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.modifyForm.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			//提交
			SubmitForm(formName) {

				// let formData = Object.assign({}, this.modifyForm);
				// this.postData(this.$urlConfig.WhCustomerUpdate, formData);

				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.modifyForm);
						// if(this.modifyForm.id){
						// this.postData(this.$urlConfig.WhPlaceEdit, [formData]);
						// let curLgsKey = this.selectOption.biz_wh_lgskey_code.find(item => {
						// 	return item.code == formData.code;
						// });
						// if (curLgsKey) {
						// 	formData.codeName = curLgsKey.codeText;
						// }
						// formData.extendParamJson = {
						// 	channels: formData.channels,
						// 	signatures: formData.signatures,
						// };
						// }
						if (formData.id) {
							this.postData(this.$urlConfig.WhCabinetPickupCarrierEdit, formData, () => {

								this.$message.success(this.$t('i18nn_994cb374e601fab3'));
								this.dialogFile = false;
								this.$emit('success')

							});
						} else {
							this.postData(this.$urlConfig.WhCabinetPickupCarrierAdd, formData, () => {

								this.$message.success(this.$t('i18nn_4ab989bd6abd3ac7'));
								this.dialogFile = false;
								this.$emit('success')

							});
						}

						// } else {
						// 	this.postData(this.$urlConfig.WhPlaceAdd, [formData]);
						// }

					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},

			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);
				this.modifyForm = clearObjectVal(this.modifyForm);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				// this.form.bckId = '';
				// this.form.userName = '';
				// this.form.userArr = [];
				// this.form.remark = '';
				// this.form.whNoArr = [];
				// this.form.whNo = "";
				// this.CateValue = [];
				// if (this.$refs.hyUpLoadImg1) {
				// 	this.$refs.hyUpLoadImg1.initUrl('');
				// }
			},

			//提交信息
			// postData(url, formData) {
			// 	this.loading = true;
			// 	this.$http
			// 		.post(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			// 					type: 'success',
			// 				});
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '提交失败,请重试';
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning',
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning',
			// 			});
			// 		});
			// },

			postData(url, formData, callback) {
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							// this.dialogFile = false;
							// this.$message.success(this.$t('tips.submitSuccess'));
							// this.$emit('success');
							callback();
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					}).catch(error => {
						console.log(error);
						// console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>